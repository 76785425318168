body {
  margin: 0;
  font-family: -apple-system, 'Urbanist', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 767px) {
  .grecaptcha-badge {
    visibility: hidden;
  }
}

/*Overrides for react-toggle component*/
.react-app-toggle .react-toggle-track {
  background-color: #eec8da !important;
}
.react-app-toggle.react-toggle--checked .react-toggle-track {
  background-color: #e2348d !important;
}
.react-app-toggle .react-toggle-thumb {
  border: 0;
}

.react-datepicker__month-container {
  float: unset !important;
}
.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  input {
  font-size: 16px;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
}

.react-datepicker__close-icon::after {
  background-color: #fd108a !important;
}

/* overrides default react-phone-input-2 styles */
.react-tel-input .form-control:focus {
  border: 1px solid #757575 !important;
}

#sib-conversations {
  z-index: 1399 !important;
}

/* width */
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(157, 157, 156, 0.46);
  border-radius: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e2348d;
  border-radius: 9px;
}
.css-mgkwpw {
  background-color: #ededed !important;
}
.css-mgkwpw[data-checked] {
  background: #e2368f !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#chakra-toast-portal ul {
  z-index: 999999 !important;
}
